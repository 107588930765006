import(/* webpackMode: "eager" */ "/home/debian/frontend-remake/lsmart-building-manager/lsmart-front/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/debian/frontend-remake/lsmart-building-manager/lsmart-front/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/home/debian/frontend-remake/lsmart-building-manager/lsmart-front/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/home/debian/frontend-remake/lsmart-building-manager/lsmart-front/node_modules/react-toastify/dist/ReactToastify.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/debian/frontend-remake/lsmart-building-manager/lsmart-front/src/app/[locale]/client-side-providers.tsx");
;
import(/* webpackMode: "eager" */ "/home/debian/frontend-remake/lsmart-building-manager/lsmart-front/src/app/[locale]/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/debian/frontend-remake/lsmart-building-manager/lsmart-front/src/components/history-tracker/history-tracker.tsx");
